<template>
    <div id="container">
      <div id="container-partners"></div>
      <div class="partners-title">
        <img src="../assets/creations/Vector-partners.png" alt="" />
        <label>Colaboradores</label>
      </div>
      <div id="container-icons-partners">
        <div id="partner">
          <img class="title-partner" src="../assets/creations/ciudadanos.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 2.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 3.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 4.png" alt="">
        </div>
        <div id="partner">
          <img class="title-partner" id="universidades" src="../assets/creations/Universidades.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 2.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 3.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 4.png" alt="">
        </div>
        <div id="partner">
          <img class="title-partner" id="estudiantes" src="../assets/creations/Estudiantes.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 2.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 3.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 4.png" alt="">
        </div>
        <div id="partner">
          <img class="title-partner" id="municipalidad" src="../assets/creations/Municipalidad.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 2.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 3.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 4.png" alt="">
        </div>
        <div id="partner">
          <img class="title-partner" id="ong" src="../assets/creations/ONG.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 2.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 3.png" alt="">
          <img class="partner-img" src="../assets/creations/Ellipse 4.png" alt="">
        </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  
  #container {
    margin-bottom: 30px;
  }
  
  #container-partners {
    /* width: 100%; */
    height: 17vh;
    background-image: url('../assets/creations/decorator.png');
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .partners-title {
    display: flex;
    justify-content: start;
    padding-left: 1rem;
    padding-right: 9rem;

    position: relative;
    font-style: italic;
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #000000;

    margin-top: 8vh;
  }
  
  .partners-title > label {
    position: absolute;
    top: 20%;
    left: 15%;
  }
  
  #container-icons-partners {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 9rem 0 9rem;
  }
  
  #partner {
    width: 12rem;
    height: 12rem;
    position: relative;
  }

  .title-partner {
    position: absolute;
    top: 4%;
    left: 10%;
    z-index: 20;
  }

  #universidades {
    top: 6%;
    left: 4%;
  }
  #estudiantes {
    top: 1%;
    left: 4%;
  }
  #municipalidad {
    top: 13%;
    left: 5%;
  }
  #ong {
    left: 8%;
  }

  .partner-img:nth-child(1) {
    position: absolute;
    top: 0;
  }
  .partner-img:nth-child(3) {
    position: absolute;
    top: 38%;
    right: 3%;
    z-index: 10;
  }
  .partner-img:nth-child(4) {
    position: absolute;
    bottom: 5%;
    left: 20%;
    z-index: 10;
  }

  /* queries */
  @media (min-width: 768px) {
    .partners-title {
      display: flex;
      justify-content: start;
      padding-left: 4rem;
      padding-right: 4rem;

      position: relative;
      font-style: italic;
      font-weight: 500;
      font-size: 40px;
      line-height: 60px;
      color: #000000;

      margin-top: 8vh;
    }

    #container-icons-partners {
      border: solid transparent 1px;
      padding: 0;
      /* width: 100%; */
      overflow-x: auto;
      flex-direction: row;
      flex-wrap: wrap-reverse;
    }
  }

  @media (min-width: 1024px) {
    .partners-title {
      padding-left: 9rem;
      padding-right: 9rem;
    }
  }



  </style>
  