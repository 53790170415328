<template>
  <div>
    <Navbar/>
    <Inicio/>
    <div class="J-section-history">
      <Proposito/>
      <Nosotros/>
      <Family/>
      <Creaciones/>
      <Footer/>
    </div>
    
  </div>
</template>

<script>
import Family from "./components/Family.vue"
import Nosotros from "./components/Nosotros"
import Creaciones from "./components/Creaciones.vue"
import Partners from "./components/Partners.vue"
import Navbar from "./components/Navbar.vue"
import Inicio from "./components/Inicio.vue"
import Footer from "./components/Footer.vue"
import Proposito from "./components/Proposito.vue"

export default {
  name: 'App',
  components: {
    Family,
    Nosotros,
    Proposito,
    Creaciones,
    Partners,
    Navbar,
    Inicio,
    Footer,
  }
}
</script>
<style lang="scss" >
    *{
      
    }
</style>
<!-- 
<style lang="scss" >
  @import "@/styles/app.scss";
</style> -->

