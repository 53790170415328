
<template>
    <div >
        <section id="proposito">
            <img class="image" src="../assets/propositos/imagePrincipal.png" alt="Image principal">
            <div class="text-content">
                <h2>Propósito</h2>
                <p> Nosotros somos, 
                    <span style="font-size: 30; font-weight: 900;"> 
                    GootWork. 
                    </span>
                    una StartUp de emprendimiento social tecnológico dedicado a innovar mediante el uso de tecnologías 
                    actuales para el desarrollo de distintas herramientas digitales que puedan ser de utilidad para las personas 
                    según su entorno social y cultural.
                </p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data: () => ({
    }),
    mounted() {

    },
    methods: {
        
    }
}
</script>
        

<style scoped>
#proposito{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding: 80px 0 ;
    background-color: #003329;
}

.image{
    grid-row: 1;
    margin-left: 80px;
    margin-top: 120px;
}
.text-content{
    grid-row: 1;
    grid-column: 2 / 3;
    margin-right: 80px;

}

#proposito h2{
    font-weight: 600;
    font-size: 64px;
    width: 349px;
    height: 162px;
    left: 30%;
    padding: 40px 0px 0px 30px;
    margin-bottom: 80px;
    position: relative;
    background: #FFBE559E;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 20px;
}

#proposito p{
    margin-left: 20px;
    font-weight: 400;
    font-size: 32px;
    align-items: center;
    text-align: justify;
    color: #fff;
}

.imagen img{
    width: 100%;
    height: 270px;
    left: 0px;
    top: 712px;
    
}
.vecor img{
    position: sticky;
    width: 100%;
    height: auto;
    left: 0%;
    top: 0%;
}

@media screen and (max-width: 1000px) {
    
    #proposito .container {
        text-align: center;
    
    }
    #proposito .container img{
        position: relative;
        width: 80%;
        height: 30%;
        top: 25%;
    }
    #proposito .container h2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 20px;
        position: relative;
        height: 10%;
        left: 35%;
        top: 10%;
    }
    #proposito .container p{
        padding: auto;
        position: relative;
        width: 90%;
        height: 20%;
        left: 20%;
        top: 60%;
        
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 120%;
        /* or 44px */
        
        display: flex;
        align-items: center;
        text-align: justify;
        color: #000000;
        border-radius: 20px;
    }
    .imagen img{
        width: 100%;
    }
    .vecor img{
        width: 100%;
    }
}

@media screen and (max-width: 600px){
    body{
        background-color: #fff;
        margin: 0;
    }
    #proposito .container {
        text-align: center;
    
    }
    #proposito .container img{
        width: 80%;
        height: 20%;
        position: relative;
        left: 5%;
        top: 25%;
    }
    #proposito .container h2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 20px;
        gap: 20px;
        width: 30%;
        height: 10%;
        left: 35%;
        top: 10%;

    }
    #proposito .container p{
        padding: auto;
        position: relative;
        width: 90%;
        height: 20%;
        left: 10%;
        top: 60%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 120%;
        /* or 44px */
        
        display: flex;
        align-items: center;
        text-align: justify;
        
        color: #000000;
        border-radius: 20px;
    }

    .imagen img{
        width: 100%;
    }
    .vecor img{
        width: 100%;
    }
}
</style>

