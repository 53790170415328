
<template>
	<div class="Creations row" id="creations-root-container"> 
        <div class="Titulo5 col-12 col-s-12">
            <h2>PORTALES</h2>
            <h3>(Nuestros Programas)</h3>
        </div>
        <div class="carrusel DeplazarCarta col-12 col-s-12">
            <div class="elemento Portals">
                <div class="CardWorld">
                    <h2>BUSINESS</h2>
                    <p>Una red social de negocios con responsabilidad y compromiso social, vendiendo y comprando por este mercado digital
                        estas siendo parte del cambio y mejora de las problematicas de tu comunidad. Empresas más humanas
                        más hermanas.
                    </p>
                    <div class="ButtonG">
                        <a href="https://wa.me/51954720526">
                            <span>DESCUBRIR</span>
						</a>
                    </div>
                </div>
            </div>
            <div class="elemento Portals">
                <div class="CardWorld">
                    <h2>SUMAQ YACHAY</h2>
                    <p>Escuela de Inteligencias multiples, arte, cultura, ciencia, idiomas, valores, tecnologia con los ultimos avances
                        en el mundo actual y sobre todo, con un gran impacto social, en convenios con diferentes organismos
                        sociales.
                    </p>
                    <div class="ButtonG">
                        <a href="https://wa.me/51954720526">
                            <span>DESCUBRIR</span>
						</a>
                    </div>
                </div>
            </div>
            <div class="elemento Portals">
                <div class="CardWorld">
                    <h2>SHIELD</h2>
                    <p>Seguridad integral en todo el sistema, botón de pánico, estadisticas en tiempo real, reduccion 
                        de criminalidad, indices de problematicas sociales, prevencion de futuras pandemias, zonas peligrosas, 
                        una aplicacion que te proteje.
                    </p>
                    <div class="ButtonG">
                        <a href="https://wa.me/51954720526">
                            <span>DESCUBRIR</span>
						</a>
                    </div>
                </div>
            </div>
            <div class="elemento Portals">
                <div class="CardWorld">
                    <h2>FAVOR CON FAVOR</h2>
                    <p>A modo interactivo este modulo se encarga de destinar un porcentaje de las ganancias de todas las aplciaciones creadas,
                        para solucionar problematicas sociales, vinculando actores de cambio y financiando proyectos innovadores.
                    </p>
                    <div class="ButtonG">
                        <a href="https://wa.me/51954720526">
                            <span>DESCUBRIR</span>
						</a>
                    </div>
                </div>
            </div>
        </div>

    </div> 
</template>


<script>
	export default {
		name:'creations-name',
		data() {
			return{
				data:[
					{
						icon:require('@/assets/creations/image.png'),
						hover:{
							title:'GW Negocios',
							subtitle:'Empresas'
						}
					},{
						icon:require('@/assets/creations/Group.png'),
						hover:{
							title:'GW Shield',
							subtitle:'Seguridad'
						}
					},{
						icon:require('@/assets/creations/sumaqYachayLogo.png'),
						hover:{
							title:'GW Sumaq Yachay',
							subtitle:'Educación'
						}
					},{
						icon:require('@/assets/creations/GWFavorLogo.png'),
						hover:{
							title:'GW Favor con Favor',
							subtitle:'Apoyo Social'
						}
					}
				],
				focusData:null
			}
		},
		methods:{
			onHover(i) {
				this.focusData = i
			},
			onExitHover() {
				this.focusData = null
			}
		}
	}
</script>


<style scoped>

	#creations-root-container{
		background-color: white;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;
	}

	#creations-items-container {
		display: flex;
		justify-content: center;
		gap: 20px;
		padding-bottom: 20px;
		padding-top: 20px;
		overflow-x: auto;
	}

	.creations-item{
		background-color: white;
		position: relative;
		min-width: 250px;
		height: 330px;
		box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.25);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.creation-preview {
		background: white;
		height: 140px;
		width: 140px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	::-webkit-scrollbar {
		background-color: white;
	}

	#hover-modal-data {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(171, 171, 171, 0.51);
	}

	#hover-modal-body{
		height: 100%;
		border: solid 1px white;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
	}


	.hover-text-title {
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
	}

	#creations-title-container {
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url('../assets/creations/Vector.png');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	
	#creations-title-container span {
		font-style: normal;
		font-weight: 700;
		font-size: 35px;
	}


	#decorator{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 80px;
		background-image: url('../assets/creations/decorator.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: none;
	}

	@media(max-width:680px){
		.creations-item {
			flex-direction: row;
			width: 300px;
			height: 550px;
		}

		.creation-preview{
			width: 100px;
			height: 100px;
		}

	}

	@media(max-width:1000px) {
		#creations-items-container {
			flex-direction: column;
			align-items: center;
			align-content: center;
		}
	}

	@media (min-width: 1024px) {
		#hover-modal-data {
			padding: 20px;
		}
	}

</style>
